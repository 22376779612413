import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchContext } from '../../context/SearchContext'
import useFetch from '../../hooks/useFetch'
import './reserve.css'

const Reserve = ({setOpen, hotelId}) => {
    const [selectedRomms, setselectedRomms] = useState([]);
    const { data, loading, error} = useFetch(`/hotels/room/${hotelId}`)
    const { dates}  = useContext(SearchContext);
    const navigate = useNavigate()
    const getDatesInRange=(start, end)=> {
        const date = new Date(start.getTime())
        let list = []
        while(date <= end) {
            list.push(new Date(date).getTime())
            date.setDate(date.getDate()+1)
        }
        return list
    }
    const allDates = getDatesInRange(dates[0].startDate, dates[0].endDate)

    const isAvailable = (roomNumber) => {
        const isFound = roomNumber.unavailableDates.some(date => allDates.includes(new Date(date).getTime()))

        return !isFound
    }
    const handleSelect = (e)=> {
        const checked = e.target.checked
        const value = e.target.value
        setselectedRomms(checked ? [...selectedRomms, value] : selectedRomms.filter((item)=> item !== value) )
    }

    const handleClick = async() => {
        try {
            await Promise.all( selectedRomms.map(async(roomId) => {
                const res = await axios.put(`/rooms/availability/${roomId}`, {dates : allDates})
                return res.data
            }))
            setOpen(false)
            navigate('/')
        } catch (error) {
            
        }
    }

  return (
    <div className='reserve'>
        <div className="rContainer">
            <FontAwesomeIcon className='rClose' icon={faCircleXmark} onClick={()=>setOpen(false)} />
            <span>Select Your Rooms: </span>
            {data.map((item)=> (
                    <div className="rItem" key={item._id} >
                        <div className="rItemInfo"> 
                             <div className="rTitle">{item.title}</div>
                             <div className="rDesc">{item.desc} </div>
                             <div className="rMax"><b> Maximum people: {item.maxPeople} </b> </div>
                             <div className="rPrice"><b> Price: {item.price} </b> </div>
                        </div>
                        <div className="rSelectRooms">
                        {item.roomNumbers.map((roomNumber) =>(
                            <div className="room" key={roomNumber._id} >
                                <label htmlFor="">{roomNumber.number}</label>
                                <input type="checkbox" value={roomNumber._id} onChange={handleSelect} disabled={!isAvailable(roomNumber)} />
                            </div>
                        ))}
                    </div>
                    </div>
                )
           )}
           <button onClick={handleClick} className='rButton'>Reserve Now</button>
        </div>
    </div>
  )
}

export default Reserve