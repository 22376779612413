import useFetch from "../../hooks/useFetch";
import "./featuredProperties.css";

const FeaturedProperties = () => {
  const {loading, data, error} = useFetch("/hotels?featured=true&limit=4")

  return (
    <div className="fp">
     {
      loading ? "Loading Please wait"
     :
     <>
     {
      data && data.map((item) => {
        return (
          <div className="fpItem" key={item._id}>
        <img
          src={ item?.photoes[0]}
          alt=""
          className="fpImg"
        />
        <span className="fpName">{item.title}</span>
        <span className="fpCity">{item.city}</span>
        <span className="fpPrice">Starting from ${item.cheapestPrice}</span>
        {item.rating && 
        <div className="fpRating">
          <button>  {item.rating} </button>
          <span>Excellent</span>
        </div>}
      </div>
        )
      })
      

      }
      </>
    }
    </div>
  );
};

export default FeaturedProperties;
