import axios from 'axios'
import React, { useContext } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../../context/AuthContext'
import { apirul } from '../../hooks/useFetch'
import './login.css'

const Login = () => {

    const [credentials, setCredentials] = useState({
        username: undefined,
        password: undefined
    })

    const {user,loading, error, dispatch} = useContext(AuthContext)

    const navigate = useNavigate()

    const handleChange = (e) => {
        setCredentials((prev) => ({...prev, [e.target.id]: e.target.value }))
    } 
    const handelClick = (async e => {
        e.preventDefault()
        dispatch({type: 'LOGIN_START'})

        try {
            const res = await axios.post(apirul+'/auth/login', credentials)
            dispatch({type: 'LOGIN_SUCCESS', payload: res.data.details})
            navigate('/')
            console.log(res.data)
        } catch (err) {
            dispatch({type: 'LOGIN_FAILURE', payload: err.response.data.message})
            //error = await err.response.data.message
        }
    })

    console.log(user,'user')
  return (
    <div className='login'>
        <div className="lContainer">
            <input onChange={handleChange} type="text" placeholder='username' id='username' />
            <input onChange={handleChange} type="password" placeholder='password' id='password' />
            <button disabled={loading} onClick={handelClick} className="lButton">Login</button>
            {error && <span>{error}</span>}
        </div>
    </div>
  )
}

export default Login