import { useEffect, useState } from "react";
import axios from 'axios'

export const apirul = 'https://node-booking.herokuapp.com/api'

const useFetch = (url) => {
    const [data, setdata] = useState([]);
    const [loading, setloading] = useState(false);
    const [error, seterror] = useState(false);

    useEffect(() => {
        const fetchData = async ()=> {
            setloading(true)
            try {
             const res = await axios.get(apirul+url)
             setdata(res.data)
            } catch (error) {
                seterror(error);
            }
            setloading(false)
        }
        fetchData()
    }, [url]);

const reFetch = async ()=> {
    setloading(true)
    try {
     const   res = await axios.get(url)
     setdata(res.data)
    } catch (error) {
        seterror(error);
    }
    setloading(false)
}
 return {loading, data, error, reFetch}
}

export default useFetch